import "./NewApplication.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { useState } from "react";
import { submitThirdForm } from "../../Api/api";

const ApplicationSecondForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [residenceAddress, setResidenceAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [documentImage, setDocumentImage] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault();
    const payload=new FormData();
    if(email) payload.append("email",email);
    if(residenceAddress) payload.append("residenceAddress",residenceAddress);
    if(permanentAddress) payload.append("permanentAddress",permanentAddress);
    if(panNumber) payload.append("panNumber",panNumber);
    if(aadharNumber) payload.append("aadharNumber",aadharNumber);
    if(documentImage) payload.append("documentImage",documentImage);
    if(profilePic) payload.append("profilePic",profilePic);
    submitThirdForm({
      payload,
      navigate,
      isLoading,
      setIsLoading,
      additionalFunc: () => navigate("/applicationthirdform"),
    });
      
  }

  const handleSubmitCustomerPhoto = (e) => {
    setProfilePic(e.target.files[0]);
  };


  const handleSubmitDocumentPhoto = (e) => {
    setDocumentImage(e.target.files[0]);
  };
  

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Loan Apply Form</h6>
            </div>
          </div>
        </div>

        <div className="newapplication15">
          <div className="newapplication16">
            <div className="newapplication17">
              <div className="newapplication20">
                <p>
                  <FaCheck />
                </p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication18">
                <p>2</p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication20">
                <p>3</p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication20">
                <p>4</p>
              </div>
            </div>
          </div>

          <div className="newapplication21">
            <div className="newapplication22">
              <div className="newapplication24">
                <label htmlFor="">Email ID</label>
                <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="John@gmail.com" />
              </div>
              <div className="newapplication23">
                <div className="newapplication24">
                  <label htmlFor="">Residence Address</label>
                  <input type="text" value={residenceAddress} onChange={(e)=>setResidenceAddress(e.target.value)} placeholder="UP" />
                </div>
                <div className="newapplication24">
                  <label htmlFor="">Permanent Address</label>
                  <input type="text" value={permanentAddress} onChange={(e)=>setPermanentAddress(e.target.value)} placeholder="Gaziabad" />
                </div>
              </div>
              <div className="newapplication23">
                <div className="newapplication24">
                  <label htmlFor="">PAN Number</label>
                  <input type="text" value={panNumber} onChange={(e)=>setPanNumber(e.target.value)} placeholder="PAN Number" />
                </div>
                <div className="newapplication24">
                  <label htmlFor="">Adhaar Number</label>
                  <input type="text" value={aadharNumber} onChange={(e)=>setAadharNumber(e.target.value)} placeholder="Adhaar number" />
                </div>
              </div>
              <div className="newapplication23">
                <div className="createstaffID5">
                  <label htmlFor="">Customer Photo</label>
                  <div className="createstaffID6">
                    <div className="createstaffID7">
                      <p onClick={() => document.getElementById("customerPhoto").click()}>
                        Click Here To
                        <br />
                        Capture
                        <br />
                        The Image
                      </p>
                      <input  style={{display: 'none'}} id="customerPhoto" accept="image/*"  type="file" onChange={handleSubmitCustomerPhoto} />
                    </div>
                  </div>
                </div>
                <div className="createstaffID5">
                  <label htmlFor="">Document Image</label>
                  <div className="createstaffID6">
                    <div className="createstaffID7">
                      <p onClick={() => document.getElementById("documentImage").click()}>
                        Click &<br />
                        Upload Document <br />
                        Image
                      </p>
                      <input  style={{display: 'none'}} id="documentImage" accept="image/*"  type="file" onChange={handleSubmitDocumentPhoto} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="newapplication13">
                <button onClick={() => navigate("/applicationfirstform")}>
                  Cancel
                </button>
                <button  onClick={handleSubmit}>
                  Save & Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(ApplicationSecondForm);
