import "./NewApplication.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useState } from "react";
import Button from "react-bootstrap-button-loader";
import { submitFormHandler } from "../../Api/api";
import { showNotification } from "../../Api/ImportantComponents";

const NewApplication = () => {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [isAccept, setIsAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!number) {
      showNotification({
        type: "error",
        message: "Please enter your number",
      });
      return;
    }
    if (!isAccept) {
      showNotification({
        type: "error",
        message: "Please accept terms and conditions",
      });
      return;
    }

    submitFormHandler({
      url: "staff/sendOtpForOutsideApplicationVerification",
      payload: { mobileNumber: number },
      navigate,
      isLoading,
      setIsLoading,
      additionalFunc: () => navigate("/verify-otp"),
    });
  };

  return (
    <>
      <div className="newapplication">
        <div className="totalusers">
          <div className="totalusers1">
            <div className="totalusers4">
              <div className="totalusers5">
                <IoIosArrowBack
                  color="#202224"
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <h6>Loan Apply Form</h6>
              </div>
            </div>
          </div>

          <div className="newapplication1">
            <div className="newapplication2">
              <div className="newapplication3">
                <h6>Verify Your Number</h6>
              </div>
              <div className="newapplication4">
                <input
                  type="number"
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                  minLength={10}
                  maxLength={10}
                  placeholder="Enter Your Phone Number"
                />
              </div>
              <div className="newapplication5">
                <input
                  onChange={() => setIsAccept(!isAccept)}
                  checked={isAccept}
                  type="checkbox"
                />
                <label htmlFor="">
                  By clicking, I accept the terms up service and privacy policy
                </label>
              </div>
              <div className="newapplication6">
                <Button loading={isLoading} onClick={handleSubmit}>
                  Generate OTP
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(NewApplication);
