import "./NewApplication.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { submitFourthForm } from "../../Api/api";

const ApplicationThirdForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([
    {
      name: "",
      relation: "",
      mobileNumber: "",
    },
  ]);

  const handleAddRelation = () => {
    setFormData([
      ...formData, 
      {
        name: "",
        relation: "",
        mobileNumber: "",
      },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { relative: formData };

    submitFourthForm({
      payload,
      setIsLoading,
      additionalFunc: () => navigate("/applicationfourthform"),
    });
  };

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Loan Apply Form</h6>
            </div>
          </div>
        </div>

        <div className="newapplication15">
          <div className="newapplication16">
            <div className="newapplication17">
              <div className="newapplication20">
                <p>
                  <FaCheck />
                </p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication20">
                <p>
                  <FaCheck />
                </p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication18">
                <p>3</p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication20">
                <p>4</p>
              </div>
            </div>
          </div>

          <div className="newapplication21">
            {formData.map((data, index) => (
              <div key={index} className="newapplication22">
                <div className="newapplication23">
                  <div className="newapplication24">
                    <label htmlFor="">Relation {index + 1}</label>
                    <input
                      type="text"
                      value={data.relation}
                      onChange={(e) =>
                        handleInputChange(index, "relation", e.target.value)
                      }
                      placeholder="Relation"
                    />
                  </div>
                  <div className="newapplication24">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      value={data.name}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="newapplication23">
                  <div className="newapplication24">
                    <label htmlFor="">Mobile Number</label>
                    <input
                      type="text"
                      value={data.mobileNumber}
                      onChange={(e) =>
                        handleInputChange(index, "mobileNumber", e.target.value)
                      }
                      placeholder="Mobile Number"
                    />
                  </div>
                  <div className="newapplication25">
                    {/* <button>Send OTP</button> */}
                  </div>
                </div>
              </div>
            ))}
            <div>
              <Button
                className="newapplication3-btn"
                onClick={handleAddRelation}
              >
                Add Relation
              </Button>
            </div>

            <div className="newapplication13 bottom-btn">
              <button onClick={() => navigate("/applicationsecondform")}>
                Cancel
              </button>
              <button onClick={handleSubmit}>Save & Next</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(ApplicationThirdForm);
