import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import HOC from "../../Components/HOC/HOC";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { OfferModel } from "../Offer Modal/OfferModal";
import { IoIosArrowForward } from "react-icons/io";
import img from "../../Img/img11.png";
import { getAllData } from "../../Api/api";

const Dashboard = () => {
  const percentage = 80;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    getAllData({
      url: "shopkeeper/dashboard",
      setIsLoading,
      setData: setData,
      params: {},
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <OfferModel show={modalShow} onHide={() => setModalShow(false)} />
      <div className="dashboard">
        <div className="dashboard1">
          <div className="dashboard22">
            <img src={img} alt="" />
          </div>

          <div className="dashboard2">
            <div className="dashboard3">
              <div className="dashboard4">
                <p>Total Loans</p>
                <div className="dashboard44">
                  <CircularProgressbar
                    value={percentage}
                    strokeWidth={50}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      pathColor: "#10507F",
                      backgroundColor: "#FFFFFF",
                      trailColor: "#E57676",
                    })}
                  />
                </div>
              </div>

              <div className="dashboard5">
                <div className="dashboard6">
                  <p>Loan Pending</p>
                  <span>
                    {data?.data?.totalLoan?.pending} <IoIosArrowForward />{" "}
                  </span>
                </div>
                <div className="dashboard7">
                  <p>Loan Collected</p>
                  <span>
                    {data?.data?.totalLoan?.collected} <IoIosArrowForward />
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard3">
              <div className="dashboard4">
                <p>EMI Payment</p>
                <div className="dashboard44">
                  <CircularProgressbar
                    value={percentage}
                    strokeWidth={50}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      pathColor: "#10507F",
                      backgroundColor: "#FFFFFF",
                      trailColor: "#E57676",
                    })}
                  />
                </div>
              </div>

              <div className="dashboard5">
                <div className="dashboard6">
                  <p>Loan EMI’s</p>
                  <span>
                    {data?.data?.emi?.totalPendingEmi} <IoIosArrowForward />{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(Dashboard);
