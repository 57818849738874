import "./NewApplication.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { getAllData, submitFirstForm } from "../../Api/api";

const NewApplicationForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [allProducts, setAllProducts] = useState([]);
  const [productId, setProductId] = useState("");
  const [applicantId, setApplicantId] = useState("");
  const [imeiNumber, setImeiNumber] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [price, setPrice] = useState("");
  const [interest, setInterest] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [cardFree, setCardFree] = useState("");
  const [processingFee, setProcessingFee] = useState("");
  const [noOfEmi, setNoOfEmi] = useState("");
  const [collectionType, setCollectionType] = useState("Monthly");
  const [emiAmount, setEmiAmount] = useState("");
  const [collectionDate, setCollectionDate] = useState("");

  const getData = () => {
    getAllData({
      url: "shopkeeper/allProduct",
      setIsLoading,
      setData: setAllProducts,
      params: {},
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = () => {
    const applicantId = localStorage.getItem("customerId");
    const payload = {};
    if (applicantId) payload.applicantId = applicantId;
    if (productId) payload.productId = productId;
    if (productId) payload.name = productId;
    if (applicantId) payload.applicantId = applicantId;
    if (imeiNumber) payload.imeiNumber = imeiNumber;
    if (releaseDate) payload.releaseDate = releaseDate;
    if (price) payload.price = price;
    if (interest) payload.interest = interest;
    if (downPayment) payload.downPayment = downPayment;
    if (cardFree) payload.cardFee = cardFree;
    if (processingFee) payload.processingFee = processingFee;
    if (noOfEmi) payload.noOfEmi = noOfEmi;
    if (collectionType) payload.collectionType = collectionType;
    if (emiAmount) payload.emiAmount = emiAmount;
    if (collectionDate) payload.collectionDate = collectionDate;

    submitFirstForm({
      url: "shopkeeper/addLoanApplication",
      setIsLoading,
      payload: payload,
      additionalFunc: () => navigate("/applicationfirstform"),
    });
  };

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Loan Apply Form</h6>
            </div>
          </div>
        </div>

        <div className="newapplication8">
          <div className="newapplication14">
            <div className="newapplication9">
              <select
                name=""
                id=""
                onChange={(e) => setProductId(e.target.value)}
                value={productId}
              >
                <option value="">Select Product</option>
                {allProducts && allProducts?.data?.docs?.length > 0 ? (
                  allProducts?.data?.docs.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))
                ) : (
                  <option value="">No Products</option>
                )}
              </select>
              {/* <input
                type="text"
                onChange={(e) => setApplicantId(e.target.value)}
                placeholder="Product Name"
                value={applicantId}
              /> */}
              <input
                type="text"
                onChange={(e) => setImeiNumber(e.target.value)}
                value={imeiNumber}
                placeholder="IMEI Number"
              />
              <input
                type="text"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                placeholder="Product Price eg"
              />
              <input
                type="text"
                onChange={(e) => setInterest(e.target.value)}
                value={interest}
                placeholder="Interest %"
              />
              <input
                type="text"
                onChange={(e) => setCardFree(e.target.value)}
                value={cardFree}
                placeholder="Card Fees"
              />
              <input
                type="text"
                onChange={(e) => setProcessingFee(e.target.value)}
                value={processingFee}
                placeholder="Processing Fees"
              />
              <input
                type="text"
                onChange={(e) => setDownPayment(e.target.value)}
                value={downPayment}
                placeholder="Down Payment"
              />
              <input
                type="text"
                onChange={(e) => setNoOfEmi(e.target.value)}
                value={noOfEmi}
                placeholder="Enter No of EMIs"
              />

              <div className="newapplication10">
                <label htmlFor="">Select Collection Type</label>
                <div className="newapplication11">
                  <div className="newapplication12">
                    <input
                      type="radio"
                      name="subscriptionType"
                      id="monthly"
                      value="Monthly"
                      onChange={(e) => setCollectionType(e.target.value)}
                      checked={collectionType === "Monthly"}
                    />
                    <label htmlFor="monthly">Monthly</label>
                  </div>
                  <div className="newapplication12">
                    <input
                      type="radio"
                      name="subscriptionType"
                      id="weekly"
                      value="Weekly"
                      onChange={(e) => setCollectionType(e.target.value)}
                      checked={collectionType === "Weekly"}
                    />
                    <label htmlFor="weekly">Weekly</label>
                  </div>
                </div>
              </div>
              <input
                type="date"
                onChange={(e) => setReleaseDate(e.target.value)}
                value={releaseDate}
                placeholder="Collection Date"
              />
              <input
                type="number"
                onChange={(e) => setEmiAmount(e.target.value)}
                value={emiAmount}
                placeholder="EMI Amount"
              />
            </div>
            <div className="newapplication13">
              <button onClick={() => navigate("/verifyotp")}>Cancel</button>
              <button onClick={() => handleSubmit()}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(NewApplicationForm);
