import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { BsFilterRight } from "react-icons/bs";
import { useEffect, useState } from "react";
import { getAllData } from "../../Api/api";

const Navbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const getData = () => {
    getAllData({
      url: "shopkeeper/getProfile",
      setIsLoading,
      setData: setData,
      params: {},
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="navbar111">
        <div className="navbar1">
          <div className="navbar2">
            <BsFilterRight onClick={toggleSidebar} size={25} color="#00000" />
            {/* <h6>Dashboard</h6> */}
            {console.log(data?.data)}
          </div>
          <div className="navbar3" onClick={() => navigate("/profile")}>
            <div className="navbar4">
              <img src={data?.data?.profilePic} alt="" />
            </div>
            <div className="navbar5">
              <p>{data?.data?.fullName?.split(" ")[0]}</p>
              <h6>
                Shopkeeper <RiArrowRightSLine color="#39434F" size={20} />
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
