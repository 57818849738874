import axios from "axios";
import { showNotification } from "./ImportantComponents";

const baseUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("staffToken");

export const Auth = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const loginHandler = async ({
  payload,
  navigate,
  isLoading,
  setIsLoading,
}) => {
  localStorage.clear();
  try {
    if (!payload.email)
      return showNotification({ type: "error", message: "Email is required" });
    if (!payload.password)
      return showNotification({
        type: "error",
        message: "Password is required",
      });

    setIsLoading(true);
    const res = await axios.post(`${baseUrl}staff/signin`, payload);
    localStorage.setItem("staffToken", res?.data?.accessToken);
    showNotification({ type: "success", message: "Login successful" });
    setIsLoading(false);
    window.location.href = "/dashboard";
    return;
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};

export const forgotPasswordHandler = async ({
  payload,
  navigate,
  nextPage,
  setIsLoading,
}) => {
  const forgotPassword = localStorage.getItem("forgotPassword");
  const forgotEmail = localStorage.getItem("forgotEmail");
  const forgotMobile = localStorage.getItem("forgotMobile");
  const forgotOtpVerification = localStorage.getItem("forgotOtpVerification");
  if (payload.password) {
    localStorage.setItem("forgotPassword", payload.password);
    nextPage();
  }
  if (payload.email) {
    try {
      setIsLoading(true);
      const res = await axios.post(`${baseUrl}staff/forgetPassword`, payload);
      localStorage.setItem("forgotEmail", payload.email);
      localStorage.setItem("forgotOtpVerificationId", res?.data?.data?._id);
      console.log(res?.data);
      nextPage();
      showNotification({ type: "success", message: res?.data?.message });
      setIsLoading(false);
    } catch (error) {
      showNotification({
        type: "error",
        message: error?.response?.data?.message,
      });
      setIsLoading(false);
      return;
    }
  }
  const customerId = localStorage.getItem("forgotOtpVerificationId");
  if (payload.otp && customerId) {
    try {
      const res = await axios.post(
        `${baseUrl}staff/forgotVerifyotp/${customerId}`,
        {
          otp: payload.otp,
        }
      );
      localStorage.setItem("forgotOtpVerification", payload.otp);
      console.log(res?.data?.userId);
      if (res?.data) {
        try {
          const res = await axios.post(`${baseUrl}staff/changePassword/${res?.data?.userId}`, {
            newPassword: payload.password,
            confirmPassword: payload.password,
            otp: payload.otp,
          });
          showNotification({ type: "success", message: res?.data?.message });

          nextPage();
          showNotification({ type: "success", message: res?.data?.message });
        } catch (error) {
          showNotification({
            type: "error",
            message: error?.response?.data?.message,
          });
        }
      }
    } catch (error) {
      showNotification({
        type: "error",
        message: error?.response?.data?.message,
      });
    }
  }
};

export const getAllData = async ({
  url,
  setData,
  setIsLoading,
  params,
  additionalFunc,
}) => {
  try {
    if (setIsLoading) setIsLoading(true);
    const res = await axios.get(`${baseUrl}${url}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(res?.data);
    if (setIsLoading) setIsLoading(false);
    if (additionalFunc) additionalFunc();
  } catch (error) {
    if (setIsLoading) setIsLoading(false);
    showNotification({
      type: "error",
      message: error?.response?.data?.message || "An error occurred",
    });
  }
};

export const postData = async ({
  url,
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  try {
    setIsLoading(true);
    const res = await axios.post(`${baseUrl}${url}`, payload, Auth);
    if (additionalFunc) additionalFunc();
    showNotification({ type: "success", message: res?.data?.message });
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    showNotification({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong",
    });
  }
};

export const putData = async ({
  url,
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  try {
    setIsLoading(true);
    const res = await axios.put(`${baseUrl}${url}`, payload, Auth);
    showNotification({ type: "success", message: res?.data?.message });
    if (additionalFunc) additionalFunc();
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    showNotification({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong",
    });
  }
};

export const deleteData = async ({ url, setIsLoading, additionalFunc }) => {
  try {
    setIsLoading(true);
    const res = await axios.delete(`${baseUrl}${url}`, Auth);
    showNotification({ type: "success", message: res?.data?.message });
    if (additionalFunc) additionalFunc();
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

export const getSingleData = async ({ url, setData, setIsLoading }) => {
  try {
    setIsLoading(true);

    const res = await axios.get(`${baseUrl}${url}`, Auth);
    setData(res?.data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);

    showNotification({
      type: "error",
      message: error?.response?.data?.message || "Something went wrong",
    });
  }
};

export const getDataWithPagination = async ({
  url,
  setData,
  setIsLoading,
  page,
}) => {
  try {
    setIsLoading(true);
    const res = await axios.get(`${baseUrl}${url}?page=${page}`, Auth);
    setData(res?.data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
  }
};

export const formatDate = (date) => {
  const newDate = date?.split("T")?.[0]?.split("-")?.reverse()?.join("-");
  return newDate;
};

export const submitFormHandler = async ({
  url,
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  if (payload.mobileNumber) {
    localStorage.setItem("number", payload.mobileNumber);
    try {
      setIsLoading(true);
      const res = await axios.post(`${baseUrl}${url}`, payload, Auth);
      showNotification({ type: "success", message: res?.data?.message });
      localStorage.setItem("verificationId", res?.data?.data?._id);
      if (additionalFunc) additionalFunc();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showNotification({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  }
};

export const verifyOtpHandler = async ({
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  const customerId = localStorage.getItem("verificationId");
  const mobileNumber = localStorage.getItem("number");
  try {
    setIsLoading(true);
    const res = await axios.post(
      `${baseUrl}staff/verificationForOutsideApplication/${customerId}`,
      payload
    );

    try {
      setIsLoading(true);
      const res = await axios.post(
        `${baseUrl}staff/addCustomer`,
        {
          mobileNumber: mobileNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("customerId", res?.data?.data?._id);
      setIsLoading(false);
      if (additionalFunc) additionalFunc();
    } catch (error) {
      showNotification({
        type: "error",
        message: error?.response?.data?.message,
      });
    }
    showNotification({ type: "success", message: res?.data?.message });
    setIsLoading(false);
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};

export const submitFirstForm = async ({
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  try {
    setIsLoading(true);
    const res = await axios.post(
      `${baseUrl}shopkeeper/createLoanApplicationStep1`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showNotification({ type: "success", message: res?.data?.message });
    localStorage.setItem("loanId", res?.data?.data?._id);
    setIsLoading(false);
    if (additionalFunc) additionalFunc();
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};
export const submitSecondForm = async ({
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  const loanId = localStorage.getItem("loanId");
  try {
    setIsLoading(true);
    const res = await axios.put(
      `${baseUrl}shopkeeper/createLoanApplicationStep2/${loanId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showNotification({ type: "success", message: res?.data?.message });
    setIsLoading(false);
    if (additionalFunc) additionalFunc();
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};

export const submitThirdForm = async ({
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  const loanId = localStorage.getItem("loanId");
  try {
    if (setIsLoading) setIsLoading(true);
    const res = await axios.put(
      `${baseUrl}shopkeeper/createLoanApplicationStep3/${loanId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showNotification({ type: "success", message: res?.data?.message });
    if (setIsLoading) setIsLoading(false);
    if (additionalFunc) additionalFunc();
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};

export const submitFourthForm = async ({
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  const loanId = localStorage.getItem("loanId");
  try {
    if (setIsLoading) setIsLoading(true);
    const res = await axios.put(
      `${baseUrl}shopkeeper/createLoanApplicationStep4/${loanId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showNotification({ type: "success", message: res?.data?.message });
    if (setIsLoading) setIsLoading(false);
    if (additionalFunc) additionalFunc();
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};

export const submitFifthForm = async ({
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  const loanId = localStorage.getItem("loanId");
  if (!loanId)
    return showNotification({
      type: "error",
      message: "Please Fill From Step 1",
    });
  try {
    if (setIsLoading) setIsLoading(true);
    const res = await axios.put(
      `${baseUrl}shopkeeper/createLoanApplicationStep5/${loanId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    localStorage.removeItem("loanId");
    localStorage.removeItem("customerId");
    localStorage.removeItem("number");
    showNotification({ type: "success", message: res?.data?.message });
    if (setIsLoading) setIsLoading(false);
    if (additionalFunc) additionalFunc();
  } catch (error) {
    showNotification({
      type: "error",
      message: error?.response?.data?.message,
    });
    setIsLoading(false);
  }
};

export const addNewApplication = async ({
  url,
  payload,
  setIsLoading,
  additionalFunc,
}) => {
  if (payload.mobileNumber) {
    try {
      setIsLoading(true);
      const res = await axios.post(`${baseUrl}${url}`, payload, Auth);
      showNotification({ type: "success", message: res?.data?.message });
      if (additionalFunc) additionalFunc();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showNotification({
        type: "error",
        message: error?.response?.data?.msg || "Something went wrong",
      });
    }
  }
};

export const numberConvert = (num) => {
  if (num) {
    return num.toFixed(2);
  }
  return 0;
};

export const daysFromDueDate = (dueDate) => {
  if (dueDate) {
    const today = new Date();
    const due = new Date(dueDate);

    const differenceInTime = due.getTime() - today.getTime();

    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  }
  return 0;
};
