import "./NewApplication.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { submitSecondForm } from "../../Api/api";
import { IoIosArrowBack } from "react-icons/io";
import { Form } from "react-bootstrap";

const ApplicationFirstForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [martialStatus, setMartialStatus] = useState("");
  const [mothersName, setMothersName] = useState("");
  const [spaouseName, setSpouseName] = useState("");
  const [education, setEducation] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {};
    if (name) payload.firstName = name;
    if (fatherName) payload.fatherName = fatherName;
    if (dob) payload.dateOfBirth = dob;
    if (gender) payload.gender = gender;
    if (martialStatus) payload.maritalStatus = martialStatus;
    if (mothersName) payload.motherName = mothersName;
    if (spaouseName) payload.spouseName = spaouseName;
    if (education) payload.education = education;
    if (jobTitle) payload.jobTitle = jobTitle;

    submitSecondForm({
      payload,
      setIsLoading,
      additionalFunc: () => navigate("/applicationsecondform"),
    });
  };

  return (
    <>
      <div className="totalusers">
        <div className="totalusers1">
          <div className="totalusers4">
            <div className="totalusers5">
              <IoIosArrowBack
                color="#202224"
                size={25}
                onClick={() => navigate(-1)}
              />
              <h6>Loan Apply Form</h6>
            </div>
          </div>
        </div>

        <div className="newapplication15">
          <div className="newapplication16">
            <div className="newapplication17">
              <div className="newapplication18">
                <p>1</p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication20">
                <p>2</p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication20">
                <p>3</p>
              </div>
              <div className="newapplication19"></div>
              <div className="newapplication20">
                <p>4</p>
              </div>
            </div>
          </div>

          <div className="newapplication21">
            <div className="newapplication22">
              <div className="newapplication23">
                <div className="newapplication24">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Jhon"
                  />
                </div>
                <div className="newapplication24">
                  <label htmlFor="">Father Name</label>
                  <input
                    type="text"
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    placeholder="Doe"
                  />
                </div>
              </div>
              <div className="newapplication23">
                <div className="newapplication24">
                  <label htmlFor="">Gender</label>
                  {/* <input
                    type="text"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    placeholder="Male"
                  /> */}
                  {
                    <Form.Select
                      name=""
                      id=""
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Select>
                  }
                </div>
                <div className="newapplication24">
                  <label htmlFor="">DOB</label>
                  <input
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </div>
              </div>
              <div className="newapplication24">
                <label htmlFor="">Marital Status</label>
                <select
                  name=""
                  id=""
                  onChange={(e) => setMartialStatus(e.target.value)}
                  value={martialStatus}
                >
                  <option value="Married">Married</option>
                  <option value="Unmarried">Unmarried</option>
                </select>
              </div>
              <div className="newapplication23">
                <div className="newapplication24">
                  <label htmlFor="">Mother Name</label>
                  <input
                    type="text"
                    value={mothersName}
                    onChange={(e) => setMothersName(e.target.value)}
                    placeholder="Ray White"
                  />
                </div>
                <div className="newapplication24">
                  <label htmlFor="">Spouse Name</label>
                  <input
                    type="text"
                    value={spaouseName}
                    onChange={(e) => setSpouseName(e.target.value)}
                    placeholder="NA"
                  />
                </div>
              </div>
              <div className="newapplication23">
                <div className="newapplication24">
                  <label htmlFor="">Education</label>
                  <input
                    type="text"
                    value={education}
                    onChange={(e) => setEducation(e.target.value)}
                    placeholder="MCZ"
                  />
                </div>
                <div className="newapplication24">
                  <label htmlFor="">Job Title</label>
                  <input
                    type="text"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    placeholder="Executive"
                  />
                </div>
              </div>
              <div className="newapplication13">
                <button onClick={() => navigate("/newapplicationform")}>
                  Cancel
                </button>
                <button onClick={handleSubmit}>Save & Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(ApplicationFirstForm);
