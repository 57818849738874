import { useEffect, useState } from "react";
import "./TotalUsers.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import img from "../../Img/img3.png";
import img1 from "../../Img/img4.png";
import img2 from "../../Img/img5.png";
import img3 from "../../Img/img6.png";
import img4 from "../../Img/img7.png";
import { formatDate, getAllData } from "../../Api/api";

const UserDetail = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { userId } = useParams();

  const tabledata1 = [
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      InterestAmount: "1200",
      ClosingEMIAmount: "35000",
      PaymentStatus: "Received",
    },
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      InterestAmount: "1200",
      ClosingEMIAmount: "35000",
      PaymentStatus: "Received",
    },
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      InterestAmount: "1200",
      ClosingEMIAmount: "35000",
      PaymentStatus: "Pending",
    },
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      InterestAmount: "1200",
      ClosingEMIAmount: "35000",
      PaymentStatus: "Pending",
    },
  ];

  const tabledata2 = [
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      TransactionID: "1234567890",
      PaymentMode: "Google PAy",
      PaymentDate: "24/05/24",
      PaymentStatus: "Successful",
    },
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      TransactionID: "1234567890",
      PaymentMode: "UPI",
      PaymentDate: "24/05/24",
      PaymentStatus: "Successful",
    },
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      TransactionID: "1234567890",
      PaymentMode: "Net Banking",
      PaymentDate: "24/05/24",
      PaymentStatus: "Successful",
    },
    {
      id: 1,
      DueDate: "24-05-2024",
      Type: "Installment",
      EMIAmount: "25000",
      TransactionID: "1234567890",
      PaymentMode: "UPI",
      PaymentDate: "24/05/24",
      PaymentStatus: "Successful",
    },
  ];

  const [query, setQuery] = useState("");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    getAllData({
      url: `admin/getUserById/${userId}`,
      setIsLoading,
      setData: setData,
      params: {},
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="newapplication">
        <div className="userdetails">
          <div className="totalusers5">
            <IoIosArrowBack
              color="#202224"
              size={25}
              onClick={() => navigate(-1)}
            />
            <h6>Customer Details</h6>
          </div>
          <div className="userdetails1">
            <div className="userdetails2">
              <div className="userdetails3">
                <div className="userdetails4">
                  <img src={data?.data?.profilePic || img} alt="" />
                </div>
                <div className="userdetails5">
                  <h6>
                    {(data?.data?.user?.firstName || "") +
                      " " +
                      (data?.data?.user?.lastName || "")}
                  </h6>
                  <p>{data?.data?.user?.status}</p>
                </div>
              </div>
              <div className="userdetails6">
                <button style={{ backgroundColor: "#00f076" }}>
                  Download Report
                </button>
                <button style={{ backgroundColor: "#2E4658" }}>Pay Now</button>
                {/* <button style={{ backgroundColor: "#2196F3" }}>
                  Share Payment Link
                </button> */}
              </div>
            </div>

            <div className="userdetails7">
              <div className="userdetails8">
                <label htmlFor="">Father’s Name</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.user?.fatherName || ""}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Mother’s Name</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.user?.motherName || ""}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Spouse’s Name</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.user?.spouseName}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Mobile No.</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.user?.mobileNumber}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">PAN No.</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.loanApplication?.panNumber}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Adhaar No.</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.loanApplication?.adhaarNumber}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.loanApplication?.email}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Residence Address</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.loanApplication?.residenceAddress}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Permanent Address</label>
                <input
                  type="text"
                  disabled
                  value={data?.data?.loanApplication?.permanentAddress}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Relation 1 (Name)</label>
                <input
                  type="text"
                  placeholder=" Relation 1 (Name)"
                  disabled
                  value={data?.data?.user?.fatherName}
                />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Relation</label>
                <input type="text" placeholder="Brother" />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Relation 1 (Mobile No.)</label>
                <input type="text" placeholder="+91 85647 58654" />
              </div>
              <div className="userdetails8">
                <label htmlFor=""> Relation 2 (Name)</label>
                <input type="text" placeholder="Aman" />
              </div>
              <div className="userdetails8">
                <label htmlFor="">Relation</label>
                <input type="text" placeholder="Brother" />
              </div>
              <div className="userdetails8">
                <label htmlFor=""> Relation 2 (Mobile No.)</label>
                <input type="text" placeholder="+91 85647 58654" />
              </div>
            </div>

            <div className="userdetails9">
              <div className="userdetails10">
                <div
                  className={step === 0 ? "userdetails11" : "userdetails12"}
                  onClick={() => setStep(0)}
                >
                  <p>Loan Account Summary</p>
                  {step == 0 && <img src={img1} alt="" />}
                </div>
                <div
                  className={step === 1 ? "userdetails11" : "userdetails12"}
                  onClick={() => setStep(1)}
                >
                  <p>Transaction Summary</p>
                  {step == 1 && <img src={img1} alt="" />}
                </div>
                <div
                  className={step === 2 ? "userdetails11" : "userdetails12"}
                  onClick={() => setStep(2)}
                >
                  <p>Loan Related Document</p>
                  {step == 2 && <img src={img1} alt="" />}
                </div>
              </div>
              {step === 0 ? (
                <div className="userdetails13">
                  <div className="totalusers9">
                    <div className="totalusers10">
                      <table>
                        <thead>
                          <tr>
                            <th>Due Date</th>
                            <th>Type</th>
                            <th>EMI Amount</th>
                            <th>Interest Amount</th>
                            <th>Closing EMI Amount</th>
                            <th>Payment Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.loanInstallment?.map((data) => (
                            <tr key={data._id}>
                              <td>{formatDate(data.dueDate)}</td>
                              <td>{data.type}</td>
                              <td>{data.emiAmount}</td>
                              <td>{data.interestAmount}</td>
                              <td>{data.closingEmiAmount}</td>
                              <td
                                style={{
                                  color:
                                    data.paymentStatus === "paid"
                                      ? "#202224"
                                      : "#F44336",
                                }}
                              >
                                {data.paymentStatus}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : step === 1 ? (
                <div className="userdetails13">
                  <div className="totalusers9">
                    <div className="totalusers10">
                      <table>
                        <thead>
                          <tr>
                            <th>Due Date</th>
                            <th>Type</th>
                            <th>EMI Amount</th>
                            <th>Transaction ID</th>
                            <th>Payment Mode</th>
                            <th>Payment Date</th>
                            <th>Payment Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.transaction?.map((data) => (
                            <tr key={data._id}>
                              <td>{formatDate(data.dueDate)}</td>
                              <td>{data.type}</td>
                              <td>{data.emiAmount}</td>
                              <td>{data.transactionId}</td>
                              <td>{data.paymentMode}</td>
                              <td>{formatDate(data.date)}</td>
                              <td
                                style={{
                                  color:
                                    data.paymentStatus === "paid"
                                      ? "#202224"
                                      : "#F44336",
                                }}
                              >
                                {data.paymentStatus}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : step === 2 ? (
                <>
                  <div className="userdetails13">
                    <div className="userdetails14">
                      <img src={img2} alt="" />
                      <img src={img4} alt="" />
                      <img src={img3} alt="" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(UserDetail);
