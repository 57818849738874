import "./EMICollection.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useState } from "react";
import { getAllData } from "../../Api/api";

const EMICollection = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [emiType, setEmiType] = useState("");

  const handleSearch = () => {
    const params = {};
    if (emiType === "imieiNumber") params.imeiNumber = emiType;
    if (emiType === "Id") params.Id = emiType;
    if (emiType === "mobileNumber") params.mobileNumber = emiType;
    if (query) {
      getAllData({
        url: `staff/getLoanApplicationByImeiNumber?${emiType}=${query}`,
        setIsLoading,
        setData: setData,
        additionalFunc: () => navigate("/emiCollection"),
      });
    }
  };

  return (
    <>
      <div className="newapplication">
        <div className="totalusers">
          <div className="totalusers1">
            <div className="totalusers4">
              <div className="totalusers5">
                <IoIosArrowBack
                  color="#202224"
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <h6>EMI Collection</h6>
              </div>
            </div>
          </div>

          <div className="emicollection">
            <div className="emicollection1">
              <div className="emicollection2">
                <label htmlFor="">Select type</label>
                <select
                  onChange={(e) => setEmiType(e.target.value)}
                  value={emiType}
                  required
                >
                  <option value=""> Select Type </option>
                  <option value="applicationId">Application ID</option>
                  <option value="mobileNo">Mobile No.</option>
                  <option value="imeiNumber">IMEI No.</option>
                </select>
              </div>
              <div className="emicollection2">
                <label htmlFor="">{emiType}</label>
                <div className="emicollection3">
                  <input
                    type="search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="XQPOGG464"
                  />
                  <IoSearch
                    style={{ cursor: "pointer" }}
                    size={20}
                    onClick={handleSearch}
                    color="#9AA3AF"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(EMICollection);
