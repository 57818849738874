import { useState } from "react";
import "./NewApplication.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { verifyOtpHandler } from "../../Api/api";
import OTPInput, { ResendOTP } from "otp-input-react";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const mobileNumber = localStorage.getItem("number");
  const [isLoading, setIsLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [OTP, setOTP] = useState("");

  const handleVerifyOtp = () => {
    const payload = {};

    if (OTP) {
      payload.otp = OTP;
    }

    verifyOtpHandler({
      payload,
      setIsLoading,
      additionalFunc: () => navigate("/newapplicationform"),
    });
  };

  const resendOtp = () => {
    const payload = {};
    if (mobileNumber) {
      payload.mobileNumber = mobileNumber;
    }
    verifyOtpHandler({
      payload,
      setIsLoading,
      navigate,
      OTP,
    });
  };
  return (
    <>
      <div className="newapplication">
        <div className="totalusers">
          <div className="totalusers1">
            <div className="totalusers4">
              <div className="totalusers5">
                <IoIosArrowBack
                  color="#202224"
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <h6>Loan Apply Form</h6>
              </div>
            </div>
          </div>

          <div className="newapplication1">
            <div className="newapplication2">
              <div className="newapplication3">
                <h6>Verify with OTP sent to {mobileNumber}</h6>
              </div>
              <div className="newapplication4">
                <div className="otp-container">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    secure
                  />
                  {/* <ResendOTP
                    onResendClick={() => console.log("Resend clicked")}
                  /> */}
                </div>
              </div>
              <div className="newapplication6">
                <button onClick={() => handleVerifyOtp()}>Continue</button>
              </div>
              <div>
                <p>
                  <ResendOTP
                    onResendClick={() => console.log("Resend clicked")}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(VerifyOtp);
