import "./TotalUsers.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { getAllData } from "../../Api/api";

const TotalUsers = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    getAllData({
      url: "staff/getLoanApplicationForStaff",
      setIsLoading,
      setData: setData,
      params: {},
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="newapplication">
        <div className="totalusers">
          <div className="totalusers1">
            <div className="totalusers4">
              <div className="totalusers5">
                <IoIosArrowBack
                  color="#202224"
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <h6>Total Users</h6>
              </div>
              {/* <div className="totalusers6">
                <div className="totalusers7">
                  <IoIosSearch color="#D5D5D5" size={20} />
                  <input
                    type="search"
                    placeholder="Search by Date, ID or software"
                  />
                </div>
                <div className="totalusers8">
                  <select name="" id="">
                    <option value="">Filter</option>
                  </select>
                </div>
              </div> */}
            </div>

            <div className="totalusers9">
              <div className="totalusers10">
                <table>
                  <thead>
                    <tr>
                      <th>Loan ID</th>
                      <th>Customer Name</th>
                      <th>Product</th>
                      <th>Mobile Number</th>
                      <th>Loan Amount</th>
                      <th>Pending Amount</th>
                      <th>Paid Amount</th>
                      <th>Status</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.docs?.map((data) => (
                      <tr key={data._id}>
                        <td>{data?.Id}</td>
                        <td>
                          {(data?.firstName || "") +
                            " " +
                            (data?.lastName || "")}
                        </td>
                        <td>{data?.productId?.name}</td>
                        <td>{data.applicantId?.mobileNumber}</td>
                        <td>{data.applicantId?.totalLoanAmount}</td>
                        <td>{data.applicantId?.pendingPayment}</td>
                        <td>{data.applicantId?.paidPayment}</td>
                        <td>
                          <div className="totalusers12">
                            <div
                              className="totalusers13"
                              style={{
                                backgroundColor:
                                  data.status === "pending"
                                    ? "#FFA500"
                                    : data.Status === "completed"
                                    ? "#2196F3"
                                    : data.Status === "active"
                                    ? "#25BC18"
                                    : "#F44336",
                              }}
                            >
                              {data.status}
                            </div>
                          </div>
                        </td>
                        {/* <td className="totalusers11">
                          <MdRemoveRedEye
                            color="#1C1B1F"
                            size={20}
                            onClick={() =>
                              navigate(
                                `/user-details/${data?.applicantId?._id}`
                              )
                            }
                          />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(TotalUsers);
