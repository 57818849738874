import "./PendingEMI.css";
import HOC from "../../Components/HOC/HOC";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { MdRemoveRedEye } from "react-icons/md";
import { useEffect, useState } from "react";
import { daysFromDueDate, formatDate, getAllData } from "../../Api/api";

const PendingEMI = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    getAllData({
      url: "staff/getPendingEmiForStaff",
      setIsLoading,
      setData: setData,
      params: {},
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="newapplication">
        <div className="totalusers">
          <div className="totalusers1">
            <div className="totalusers4">
              <div className="totalusers5">
                <IoIosArrowBack
                  color="#202224"
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <h6>Pending EMI’s</h6>
              </div>
              <div className="totalusers6">
                {/* <div className="totalusers7">
                  <IoIosSearch color="#D5D5D5" size={20} />
                  <input
                    type="search"
                    placeholder="Search by Date, ID or software"
                  />
                </div> */}
                {/* <div className="totalusers8">
                  <select name="" id="">
                    <option value="">Filter</option>
                  </select>
                </div> */}
              </div>
            </div>

            <div className="totalusers9">
              <div className="totalusers10">
                <table>
                  <thead>
                    <tr>
                      <th>Loan ID</th>
                      <th>Customer Name</th>
                      <th>EMI Date</th>
                      <th>EMI Amount</th>
                      <th>Total Pending Amount</th>
                      <th>Due (No. Of Days)</th>

                      <th>Mobile Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.docs?.map((data) => (
                      <tr style={{ textAlign: "center" }} key={data._id}>
                        <td>{data.loanApplicationId?.Id}</td>
                        <td>
                          {(data.applicantId?.firstName || "") +
                            " " +
                            (data.applicantId?.lastName || "")}
                        </td>
                        <td>{formatDate(data.dueDate)}</td>
                        <td>{data.emiAmount}</td>
                        <td>{data.loanApplicationId?.pendingAmount}</td>
                        <td>{daysFromDueDate(data.dueDate)}</td>
                        <td>{data?.applicantId?.mobileNumber}</td>
                        <td className="totalusers11">
                          <MdRemoveRedEye
                            color="#1C1B1F"
                            size={20}
                            onClick={() =>
                              navigate(
                                `/user-details/${data?.applicantId?._id}`
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HOC(PendingEMI);
